.App {
  text-align: center;
}

.fullscreen {
  /* width: 100vw; */
  /* height: 100vh; */
}

@media (min-width: 768px) {
  .fullscreen {
    /* width: 100vw; */
    height: 100vh;
  }
}

/* Helpers */
.img-absolute {
  position: absolute;
}

.no-padd {
  padding: 0;
}

.pn-text-orange {
  color: #FF4525;
}

.pn-text-gray {
  color: #364550;
}

.pn-text-white {
  color: white;
}

.pn-bg-orange {
  background-color: #FF4525;
}

.pn-bg-gray {
  background-color: #364550;
}

/* fonts */
@font-face {
  font-family: 'DMSans';
  font-weight: 400;
  font-style: normal;
  src: url('/public/assets/fonts/DMSans-Regular.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'DMSans';
  font-weight: 100;
  src: url('/public/assets/fonts/DMSans-Light.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'DMSans';
  font-weight: bold;
  src: url('/public/assets/fonts/DMSans-Bold.ttf') format('truetype');
  /* Safari, Android, iOS */
}

/* @font-face {
  font-family: 'DMSans';
  font-weight: bold;
  src: url('/public/assets/fonts/DMSans-Bold.woff') format('woff'), /* Modern Browsers 
       url('/public/assets/fonts/DMSans-Bold.ttf')  format('truetype'); /* Safari, Android, iOS 
} */

body {
  /* font-family: 'DMSans';
  font-weight: 100; */
  font-weight: normal;
  text-align: center;
}

.snowflake {
  color: #fff;
  font-size: 1.7em;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 1px #000;
}

.snowflake,
.snowflake .inner {
  animation-iteration-count: infinite;
  animation-play-state: running
}

@keyframes snowflakes-fall {
  0% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(110vh)
  }
}

@keyframes snowflakes-shake {

  0%,
  100% {
    transform: translateX(0)
  }

  50% {
    transform: translateX(80px)
  }
}

.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  animation-name: snowflakes-shake;
  animation-duration: 3s;
  animation-timing-function: ease-in-out
}

.snowflake .inner {
  animation-duration: 10s;
  animation-name: snowflakes-fall;
  animation-timing-function: linear
}

.snowflake:nth-of-type(0) {
  left: 1%;
  animation-delay: 0s
}

.snowflake:nth-of-type(0) .inner,
.snowflake:nth-of-type(25) .inner {
  animation-delay: 0s
}

.snowflake:first-of-type {
  left: 10%;
  animation-delay: 1s
}

.snowflake:first-of-type .inner,
.snowflake:nth-of-type(8) .inner {
  animation-delay: 1s
}

.snowflake:nth-of-type(2) {
  left: 20%;
  animation-delay: .5s
}

.snowflake:nth-of-type(2) .inner,
.snowflake:nth-of-type(6) .inner {
  animation-delay: 6s
}

.snowflake:nth-of-type(3) {
  left: 30%;
  animation-delay: 2s
}

.snowflake:nth-of-type(11) .inner,
.snowflake:nth-of-type(3) .inner {
  animation-delay: 4s
}

.snowflake:nth-of-type(4) {
  left: 40%;
  animation-delay: 2s
}

.snowflake:nth-of-type(10) .inner,
.snowflake:nth-of-type(4) .inner {
  animation-delay: 2s
}

.snowflake:nth-of-type(5) {
  left: 50%;
  animation-delay: 3s
}

.snowflake:nth-of-type(5) .inner,
.snowflake:nth-of-type(21) .inner {
  animation-delay: 8s
}

.snowflake:nth-of-type(6) {
  left: 60%;
  animation-delay: 2s
}

.snowflake:nth-of-type(7) {
  left: 70%;
  animation-delay: 1s
}

.snowflake:nth-of-type(7) .inner,
.snowflake:nth-of-type(21) .inner {
  animation-delay: 2.5s
}

.snowflake:nth-of-type(8) {
  left: 80%;
  animation-delay: 0s
}

.snowflake:nth-of-type(9) {
  left: 90%;
  animation-delay: 1.5s
}

.snowflake:nth-of-type(9) .inner {
  animation-delay: 3s
}

.snowflake:nth-of-type(10) {
  left: 25%;
  animation-delay: 0s
}

.snowflake:nth-of-type(11) {
  left: 65%;
  animation-delay: 2.5s
}

.snowflake:nth-of-type(12) {
  left: 15%;
  animation-delay: 3.5s;
}

.snowflake:nth-of-type(12) .inner {
  animation-delay: 3.5s;
}

.snowflake:nth-of-type(13) {
  left: 35%;
  animation-delay: 2.7s;
}

.snowflake:nth-of-type(13) .inner,
.snowflake:nth-of-type(22) .inner {
  animation-delay: 2.7s;
}

.snowflake:nth-of-type(14) {
  left: 55%;
  animation-delay: 4s;
}

.snowflake:nth-of-type(14) .inner,
.snowflake:nth-of-type(22) .inner {
  animation-delay: 4s;
}

.snowflake:nth-of-type(15) {
  left: 75%;
  animation-delay: 1.2s;
}

.snowflake:nth-of-type(15) .inner {
  animation-delay: 1.2s;
}

.snowflake:nth-of-type(16) {
  left: 10%;
  animation-delay: 2.3s;
}

.snowflake:nth-of-type(16) .inner {
  animation-delay: 2.3s;
}

.snowflake:nth-of-type(17) {
  left: 30%;
  animation-delay: 3.1s;
}

.snowflake:nth-of-type(17) .inner {
  animation-delay: 3.1s;
}

.snowflake:nth-of-type(18) {
  left: 50%;
  animation-delay: 1.8s;
}

.snowflake:nth-of-type(18) .inner {
  animation-delay: 1.8s;
}

.snowflake:nth-of-type(19) {
  left: 70%;
  animation-delay: 2.6s;
}

.snowflake:nth-of-type(19) .inner {
  animation-delay: 2.6s;
}

.snowflake:nth-of-type(20) {
  left: 90%;
  animation-delay: 3.4s;
}

.snowflake:nth-of-type(20) .inner {
  animation-delay: 3.4s;
}

.snowflake:nth-of-type(21) {
  left: 5%;
  animation-delay: 2s;
}

.snowflake:nth-of-type(21) .inner {
  animation-delay: 2s;
}

.snowflake:nth-of-type(22) {
  left: 25%;
  animation-delay: 4s;
}

.snowflake:nth-of-type(22) .inner {
  animation-delay: 4s;
}

.snowflake:nth-of-type(23) {
  left: 45%;
  animation-delay: 1.5s;
}

.snowflake:nth-of-type(23) .inner {
  animation-delay: 1.5s;
}

.snowflake:nth-of-type(24) {
  left: 65%;
  animation-delay: 3.7s;
}

.snowflake:nth-of-type(24) .inner {
  animation-delay: 3.7s;
}

.snowflake:nth-of-type(25) {
  left: 85%;
  animation-delay: 2.8s;
}

.snowflake:nth-of-type(25) .inner {
  animation-delay: 2.8s;
}


.navidad .trineo {
  width: 125px;
  transform: translateY(18px);
  position: fixed;
  bottom: 0;
  left: 15px;
}

.navidad .renosFront {
  position: fixed;
  bottom: 0;
  left: 125px;
}

.navidad .renosFront .reno {
  width: 80px;
}

.navidad .renosBack {
  position: fixed;
  bottom: 0;
  left: 125px;
  opacity: 0.7;
}

.navidad .renosBack .reno {
  width: 65px;
}

.navidad .santa {
  width: 70px;
  position: fixed;
  bottom: 0;
  left: 350px;
}

.navidad .arbol {
  height: 150px;
  position: fixed;
  bottom: 0;
  right: 100px;
}

.navidad .nieve {
  width: 70px;
  position: fixed;
  bottom: 0;
  right: 50px;
}

@media (max-width: 750px) {

  .navidad .trineo {
    width: 70px;
  }

  .navidad .renosFront {
    left: 90px;
  }

  .navidad .renosBack {
    position: fixed;
    bottom: 0;
    left: 90px;
    opacity: 0.7;
  }

  .navidad .renosFront .reno {
    width: 45px;
  }

  .navidad .renosBack .reno {
    width: 35px;
  }

  .navidad .santa {
    width: 45px;
    left: 225px;
  }

  .navidad .arbol {
    height: 90px;
  }

  .navidad .nieve {
    width: 40px;
  }

}

@media (max-width: 480px) {
  .navidad .renosFront,
  .navidad .renosBack, 
  .navidad .trineo{
    display: none;
  }

  .navidad .santa {
    left: 20px;
  }

  .navidad .arbol {
    height: 90px;
    right: 45px;
  }

  .navidad .nieve {
    width: 40px;
    right: 15px;
  }
}