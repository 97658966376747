.main_scan {
    width: 100%;
}

/* titulo */

.main_scan .contenedor .titulo_marcacion {
    margin-bottom: 40px;
}

.main_scan .contenedor .titulo_marcacion h3 {
    font-size: calc(1.5rem + .6vw);
}

.main_scan .contenedor .titulo_marcacion h4 {
    color: #EB472A;
}

/* Carrusel banner ig */

.main_scan .contenedor .slide-carrusel {
    display: "flex";
    flex-direction: "column";
    justify-content: "space-around";
    width: 100vw;
    margin: auto;
    margin-bottom: 100px;
    max-width: 1200px;
}

.main_scan .contenedor .slide-carrusel .slick-slide>div {
    margin: 0 10px;
}

.main_scan .contenedor .slide-carrusel .slick-list {
    margin: 0 -10px;
}

.main_scan .contenedor .slide-carrusel .slick-slide {
    transition: all 0.3s ease;
    opacity: 0.7;
    transform: scale(0.9);

}

.main_scan .contenedor .slide-carrusel .slick-slide.slick-center {
    transform: scale(1.1);
    z-index: 1;
    opacity: 1;
}

.main_scan .contenedor .img_check_in_ig img {
    width: 100%
}

@media(max-width: 1200px) {

    .main_scan .contenedor .slide-carrusel .slick-slide>div {
        margin: 0 0px;
    }

    .main_scan .contenedor .slide-carrusel .slick-list {
        margin: 0 -5px;
    }

    .main_scan .contenedor .slide-carrusel .slick-slide {
        opacity: 1;
    }
}