.easy-use {
    padding-top: 6vh;
}

.easy-use h1 {
    font-size: 3.5vw;
    font-weight: bold;
    color: #FF4525;
    margin: 5vh auto;
}

.easy-use .contenedor {
    margin: auto;
    position: relative;
    max-width: 1200px;
    background-color: #ffffff;
    z-index: -1;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 30px;
}

.easy-use .contenedor::before {
    position: absolute;
    content: "";
    background-color: #c5c5c5;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    border-radius: 20px;
}

.easy-use .contenedor .proceso {
    color: #ffffff;
    width: 50%;
    height: 90%;
    margin: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1.7vw;
    background-color: #00000049;
    backdrop-filter: blur(10px);
    z-index: 1;
    border-radius: 20px;
}

.easy-use .contenedor .proceso .pasos-proceso {
    width: 90%;
    height: auto;
    padding: 1.5vw;
    display: flex;
    flex-direction: row;
    gap: 0.7vw;
    text-align: left;
    align-items: center;
}

.easy-use .contenedor .proceso .texto-procesos h3 {
    font-size: 1vw;
    font-weight: bold;
    margin-left: 1.5vw;
    margin-bottom: 0;
}

.easy-use .texto-procesos p {
    font-weight: 100;
    font-size: 2.5vw;
    margin: 0;
}

.easy-use .icono-proceso {
    max-width: 5vw;
    max-height: 5vw;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1vw;
}

.easy-use .icono-proceso img {
    width: 100%;
    height: 100%;
}

.easy-use .contenedor .video-proceso {
    width: 50%;
    z-index: 1;
}

.easy-use .contenedor .video-proceso video {
    width: 70%;
    border-radius: 20px;
    margin: 3vh 0;
}

@media (max-width: 768px) {

    .easy-use h1 {
        font-size: 9vw;
    }

    .easy-use .contenedor {
        flex-direction: column;
    }

    .easy-use .contenedor .video-proceso {
        width: 100%;
        z-index: 1;
    }

    .easy-use .contenedor .video-proceso video {
        width: 90%;
        border-radius: 20px;
        margin: 3vh 0;
    }

    .easy-use .contenedor .proceso {
        width: 100%;
        display: grid;
        margin: 0;
        padding: 20px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        gap: 20px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

    }

    .easy-use .contenedor .proceso .pasos-proceso {
        width: 100%;
        flex-direction: column;
        text-align: center;
        gap: 10px;
    }

    .easy-use .icono-proceso {
        max-width: 15vw;
        max-height: 15vw;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 1vw;
    }

    .easy-use .contenedor .proceso .texto-procesos h3 {
        margin-left: 0;
        font-size: 2vw;
    }

    .easy-use .texto-procesos p {
        font-size: 3.5vw;
        margin: 0;
    }

    .easy-use .icono-proceso img {
        width: 100%;
        height: 100%;
    }
}