.main_functio2 {
    width: 100%;
}

.main_functions2 .main_contenedor {
    max-width: 1200px;
    margin: auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 50px 0;
}

.main_functions2 .main_contenedor .logo {
    max-width: 250px;
    background-color: #212529;
    padding: 20px;
    border-radius: 20px;
}

.main_functions2 .main_contenedor .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px;
    background-color: #22223631;
    border-radius: 20px;
}

.main_functions2 .main_contenedor .info .text_info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 40%;
}

.main_functions2 .main_contenedor .info .text_info .btns_info {
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-bottom: 15px;
    border-bottom: 4px solid #FF4525;
}

.main_functions2 .main_contenedor .info .text_info .btns_info a {
    text-decoration: none;
    color: #Ffffff;
    background-color: #212529;
    transition: all 0.2s ease;
    padding: 10px;
    border-radius: 5px;
    min-width: 125px;
    text-align: center;
}

.main_functions2 .main_contenedor .info .text_info .btns_info a:hover {
    background-color: #FF4525;
}

.main_functions2 .main_contenedor .info .img_info {
    width: 60%;
}

.main_functions2 .main_contenedor .info .img_info img {
    width: 100%;
}

.main_functions2 .main_contenedor .info h1 {
    font-weight: bold;
    font-size: clamp(25px, 2vw, 35px);
    margin-top: 40px;
}

.main_functions2 .main_contenedor .info h4 {
    font-weight: 400;
    font-size: clamp(15px, 2vw, 25px);
    margin-bottom: 40px;
}

.main_functions2 .detalles {
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
    margin: auto;
}

.main_functions2 .detalles .punto {
    text-align: center;
}

.main_functions2 .detalles .punto img {
    width: 75px;
    height: 75px;
    background-color: #00000021;
    padding: 10px;
    border-radius: 15px;
    box-shadow: none;
    transition: all 0.3s ease;
}

.main_functions2 .detalles .punto h2 {
    color: #000000;
    font-size: 15px;
    margin-top: 10px;
    transition: all 0.3s ease;
    font-size: clamp(10px, 2vw, 15px);
    max-width: 70px;
}

.main_functions2 .detalles .punto:hover h2 {
    color: #000000;
}

/*//////// novidad ///////////*/

.main_functions2 .gorro-navidad {
    width: 5px;
    transform: scale(20) translate(1px, -0.5px);

}

.main_functions2 .navidad img {
    width: 5px;
}

.main_functions2 .navidad img {
    transform: scale(20) translate(1px, -0.5px);
}


@media(max-width: 768px) {

    .main_functions2 .gorro-navidad {
        width: 5px;
        transform: scale(20) translate(-5px, -0.5px);

    }

    .main_functions2 .main_contenedor {
        max-width: 1200px;
        margin: auto;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 30px 0;
    }

    .main_functions2 .detalles .punto img {
        width: 60px;
        height: 60px;
    }

    .main_functions2 .main_contenedor .info {
        flex-direction: column;
    }

    .main_functions2 .main_contenedor .info .img_info {
        width: 100%;
    }

    .main_functions2 .main_contenedor .info .text_info {
        width: 95%;
        align-items: center;
        text-align: center;
    }

    .main_functions2 .main_contenedor .info h1 {
        margin-top: 30px;
    }

    .main_functions2 .main_contenedor .info h4 {
        margin-bottom: 30px;
    }

    .main_functions2 .main_contenedor .info .img_info {
        padding: 30px 0;
    }
}
