.main_check_in {
    width: 100%;
}

/* titulo */

.main_check_in .contenedor .titulo_marcacion {
    margin-bottom: 40px;
}

.main_check_in .contenedor .titulo_marcacion h3 {
    font-size: calc(1.5rem + .6vw);
}

.main_check_in .contenedor .titulo_marcacion h4 {
    color: #EB472A;
}

/* Carrusel banner ig */

.main_check_in .contenedor .slide-carrusel {
    display: "flex";
    flex-direction: "column";
    justify-content: "space-around";
    width: 100vw;
    margin: auto;
    margin-bottom: 100px;
    max-width: 1200px;
}

.main_check_in .contenedor .slide-carrusel .slick-slide>div {
    margin: 0 10px;
}

.main_check_in .contenedor .slide-carrusel .slick-list {
    margin: 0 -10px;
}

.main_check_in .contenedor .slide-carrusel .slick-slide {
    transition: all 0.3s ease;
    opacity: 0.7;
    transform: scale(0.9);

}

.main_check_in .contenedor .slide-carrusel .slick-slide.slick-center {
    transform: scale(1.1);
    z-index: 1;
    opacity: 1;
}

.main_check_in .contenedor .img_check_in_ig img {
    width: 100%
}

@media(max-width: 1200px) {

    .main_check_in .contenedor .slide-carrusel .slick-slide>div {
        margin: 0 0px;
    }

    .main_check_in .contenedor .slide-carrusel .slick-list {
        margin: 0 -5px;
    }

    .main_check_in .contenedor .slide-carrusel .slick-slide {
        opacity: 1;
    }
}





/* caracteristicas */
.main_check_in .contenedor .caracteristicas_horario {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
}

.main_check_in .contenedor .caracteristicas_horario .caracteristica {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 250px;
    background-color: #c4c4c4;
    color: #000000;
    border-radius: 10px;
    padding: 25px 25px 0px 25px;
    transition: all 0.3s ease;
    border: none;
}

.main_check_in .contenedor .caracteristicas_horario img {
    width: 90px;
    transition: all 0.3s ease;
    border-radius: 10px;
    background-color: #ffffff7d;
    padding: 10px;
}

.main_check_in .contenedor .caracteristicas_horario .caracteristica:hover {
    background-color: #5F5E5E;
    color: #ffffff;
}

.main_check_in .contenedor .caracteristicas_horario .caracteristica:hover img {
    filter: brightness(0) invert(1);
    background-color: #ffffff00;
    padding: 0;
}

@media(max-width: 1200px) {

    .main_check_in .contenedor .slide-carrusel .slick-slide>div {
        margin: 0 0px;
    }

    .main_check_in .contenedor .slide-carrusel .slick-list {
        margin: 0 -5px;
    }

    .main_check_in .contenedor .slide-carrusel .slick-slide {
        opacity: 1;
    }
}


/* secciones */
.main_check_in .contenedor .slide-secciones {
    max-width: 1200px;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

.main_check_in .contenedor .slide-secciones .secciones_container {
    max-width: 500px;
}

.main_check_in .contenedor .slide-secciones .img_slider img{
    height: 400px;
}

.main_check_in .contenedor .slide-secciones .secciones_container .seccion {
    display: flex;
    flex-direction: row;
}

.main_check_in .contenedor .slide-secciones .secciones_container .seccion .texto {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.main_check_in .contenedor .slide-secciones .secciones_container .seccion h3 {
    font-size: calc(1.6rem + .6vw);
    color: #EB472A;
}

.main_check_in .contenedor .slide-secciones .secciones_container .seccion .texto .imagenes_section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.main_check_in .contenedor .slide-secciones .secciones_container .seccion .texto img {
    height: 100px;
    width: 100px
}

.main_check_in .contenedor .slide-secciones .secciones_container .seccion .texto button{
   border: none;
}

.main_check_in .contenedor .slide-secciones .secciones_container .seccion .texto .seleccionada {
    height: 125px;
    width: 125px;
    background-color: #EB472A;
    padding: 10px;
    border-radius: 100px;
}

@media(max-width: 1000px){
    .main_check_in .contenedor .slide-secciones {
        display: flex;
        flex-direction: column;
    }
}