.main-cards {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 40px;

}

.main-cards .card {
    display: flex;
    flex-direction: row;
    gap: 20px;
    border: none;
}

.main-cards .content-card {
    padding: 10px;
    display: flex ;
    flex-direction: column;
    align-items: center;
    width: 70%;
    border-radius: 20px;
    box-shadow: 1px 1px 5px #bebebe,
             -1px -5px 5px #ffffff;
}

.main-cards .content-card a p{
    width: 100%;
}

.main-cards .card h2 {
    margin: 0;
    font-size: 22px;
    font-weight: bold;
}

.main-cards .card p {
    margin: 5px;
}

.main-cards .card a {
    width: 100%;
    text-decoration: none;
    color: #000000;
    display: flex;
    flex-direction: row;
    gap: 30px;
}



.main-cards .img-card {
    width: 90px;
    height: 90px;
    border: solid 2px #FF4525;
    align-content: center;
    border-radius: 100px;
}

.main-cards .img-card img {
    width: 50%;
}

@media(max-width: 950px) {

    .main-cards {
        gap: 30px;
        margin: auto;
    }

    .main-cards .card {
        justify-content: center;
    }

}



@media(max-width:600px) {
    .main-cards .content-card p{
        font-size: 14px
    }

    .main-cards .card {
        align-items: center;
        flex-direction: row;
    }

    .main-cards .img-card {
        width: 70px;
        height: 70px;
        border: solid 2px #FF4525;
        align-content: center;
        border-radius: 100px;
    }

    .main-cards .card a {
        align-items: center;
        flex-direction: row;
    }

    .main-cards {
        max-width: 650px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 20px;
    }
}