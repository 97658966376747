.questions {
    padding: 50px 0;
}

.questions .contenedor {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.questions .preguntas ul {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.questions .contenedor h1 {
    font-size: 40px;
    font-weight: bold;
    padding-left: 30px;
    padding-bottom: 30px;
}

.questions .contenedor li {
    font-size: 20px;
}

.questions .respuesta {
    width: 300px;
    height: 500px;
    background-color: #9CA5B2;
    border-radius: 20px;
}

.questions .video_ayuda {
    background-color: #9CA5B2;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    align-content: center;
    
}

.questions .video_ayuda .cargando {
    width: 60px;
    height: 60px;
    margin: auto;
}

.questions .video_ayuda img {
    height: 100%;
    width: 100%;
}

.questions .video_ayuda video {
    width: 100%;
    width: 100%;
    border-radius: 15px;
}

.questions li {
    transition: all 0.3s ease;
    border-radius: 10px;
    padding: 3px;
    cursor: pointer;
}

.questions li:hover {
    background-color: #bdbdbd;
}

.questions .active {
    background-color: #FF602E;
    color: #ffffff;
    transform: scale(1.1) translateX(27px);
}

.questions .active:hover {
    background-color: #FF602E;
}

.questions li p {
    margin: 0;
}

@media(max-width: 930px) {
    .questions .contenedor li {
        font-size: 15px;
        width: 90%;
    }

    .questions .contenedor {
        flex-direction: column;
    }

    .questions .active {
        background-color: #FF602E;
        color: #ffffff;
        transform: scale(1.1) ;
    }
}