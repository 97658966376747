.floating-whatsapp {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
}
.floating-whatsappF {
  width: 50px;
  height: 50px;
}
.floating-whatsapp:hover {
  background-color: #128c7e;
}
@media (min-width: 992px) {
  .floating-whatsappF {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 1200px) {
  .floating-whatsappF {
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 1400px) {
  .floating-whatsappF {
    width: 70px;
    height: 70px;
  }
}