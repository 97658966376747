.language-switcher {
    position: relative;
    display: inline-block;
    align-self: center;
}

.language-switcher .idioma-button {
    background-color: #00000036;
    border: none;
    padding: 7px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
    color: #ffffff;
}

.language-switcher .icon {
    width: 20px;
    height: 20px;
}

.language-switcher .idioma-menu {
    position: absolute;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    
}

.language-switcher .idioma-item {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    width:  100%;
    text-align: left;
    justify-content: flex-start;
    gap: 5px;
}


.language-switcher .idioma-item:hover {
    background-color: #00000010;
}