.main_movil {
    width: 100%;
}

.main_movil .explorar {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    width: 100%;
    margin: auto;

}


.main_movil .btnPasos {
    width: 40%;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
}

.main_movil .btnPasos .btnPaso {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: none;
    padding: 0;
    background-color: inherit;
    transition: all 0.3s ease;
    align-items: center;
    background-color: #212529;
    padding: 7px;
    border-radius: 10px;
}

.main_movil .btnPasos .btnPaso .imgBtnPaso {
    max-width: 30px;
    margin: 0;
    background-color: inherit;
    transition: all 0.2s ease;
    border-radius: 10px;
    background-color: #212529;
}

.main_movil .btnPasos .btnPaso .imgBtnPaso img {
    width: 100%;
    padding: 0
}

.main_movil .btnPasos .btnPaso p {
    color: #ffffff;
    font-weight: 100;
    max-width: 80%;
    width: 100%;
    transition: all 0.3s ease;
    border-bottom: 4px solid #ff462500;
    padding: 0px;
    font-size: 20px;
    margin: auto;
}

.main_movil .btnPasos .btnSelected p {
    border-bottom: 4px solid #FF4525;
}

.main_movil .btnPasos .btnPaso:hover p {
    border-bottom: 4px solid #ff7e67;
}



.main_movil .manual {
    width: 50% !important;
}


.main_movil .paso {
    position: relative;
    z-index: 1;
}

.main_movil .paso .imgFondo {
    width: 60%;
    margin: auto;
    border-radius: 15px;
}

.main_movil .paso .imgFondo img {
    width: 100%;
    border-radius: 15px;
}

.main_movil .paso .texto {
    align-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 100%;
    color: transparent;
    background-color: #00000000;
    transition: all 0.3s ease;
    border-radius: 15px;
}

.main_movil .paso .texto:hover {
    background-color: #000000cb;
    color: #ffffff;
}

.main_movil .paso .texto img {
    opacity: 0;
    transition: all 0.3s ease;
    width: 30%;
    background-color: #00000093;
    padding: 10px;
    border-radius: 10px;
}

.main_movil .paso .texto:hover img {
    opacity: 0.8;
}

.main_movil .paso .texto * {
    width: 70%;
    margin: auto;
    padding: 20px 0;
}

.main_movil .paso .texto * {
    width: 70%;
    margin: auto;
    padding: 5px 0;
}



.main_movil .slick-track {
    display: flex;
}

.main_movil .slick-slide {
    margin: auto 0;
}

.main_movil .slick-next {
    transform: translateX(-55px);
}

.main_movil .slick-prev {
    transform: translateX(35px);
    z-index: 1000;
}

.main_movil .slick-arrow::before {
    color: #FF4525;
    font-size: 40px;
}

.main_movil .slick-arrow:hover:before {
    color: #666666;
}



.main_movil .slick-dots li button:before {
    font-size: 15px;
    font-weight: bold;
}

.main_movil .mas_informacion {
    margin: 20px auto;
    width: 170px;
    height: 40px;
}

.main_movil .mas_informacion a {
    border: none;
    width: 100%;
    height: 100%;
    background-color: #FF4525;
    border-radius: 5px;
    color: #ffffff;
    align-content: center;
}

.main_movil .mas_informacion a:hover {
    width: 100%;
    height: 100%;
    background-color: #f7806c;
}


@media(max-width: 850px) {
    .main_movil .explorar {
        flex-direction: column;
    }

    .main_movil .btnPasos {
        width: 70%;
        padding: 60px 40px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        margin: 0 auto;
    }

    .main_movil .manual {
        width: 90% !important;
    }

    .main_movil .manual {
        display: none;
    }

    .main_movil .btnCollapseMovil .paso * {
        width: 80%;
        margin-top: 10px;
        margin-bottom: 15px;
    }
}

@media(min-width: 850px) {
    .main_movil .btnCollapseMovil .paso{
        display: none;
    }
}

@media(max-width: 600px) {
    .main_movil .explorar {
        flex-direction: column;
    }

    .main_movil .btnPasos {
        padding: 20px 40px;
        width: 100%;
    }

    .main_movil .manual {
        width: 100% !important;
    }

    .main_movil .manual {
        padding-top: 20px !important;
    }

    .main_movil .descripcion {
        margin-bottom: 30px !important;
    }

    
}