.when-use {
    padding: 50px;
    padding-top: 10px;
}

.when-use .contenedor {
    margin: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.when-use h1{
    font-size: 45px;
    color: #000000;
    font-weight: bold;
    padding: 20px;
}

.when-use .container-text {
    padding-top: 50px;
    padding-left: 50px;
    width: 50%;
    text-align: left;
    background-color: #2423231e;
    color: #FF4525;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;
}

.when-use .container-text h2 {
    width: 80%;
    font-size: 45px;
}
.when-use .container-text p {
    width: 90%;
    font-size: 20px;
    color: #00000093;
}

.when-use .container-text ul {
    width: 100%;
    font-size: 25px;
    color: #00000093;
}

.when-use .container-text ul p {
    font-size: 30px;
}

.when-use .container-img {
    width: 50%;
}

.when-use .container-img .card-donde {
    margin: auto;
    width: 100%;
}

.when-use .container-img .card-donde .main-img {
    width: 100%;
    padding: 5%;

}



.when-use .container-img .card-donde .icono-donde img {
    margin: auto;
    width: 85%;
    height: auto;
}

.when-use .container-img .cualidades {
    display: none;
    background-color: #000000;
    height: 80px;
    width: 85%;
    margin: auto;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
}

.when-use .container-img .cualidades img {
    height: 30px;
    width: auto;
}


/*--------dots------*/

.when-use .slick-dots button {
    transform: translateY(50px);
}

.when-use .slick-dots button::before {
    font-family: 'slick';
    font-size: 120px;
    content: '-';
    opacity: .5;
    color: #FF4525;
    transform: translateY(-50px);
}

.when-use .slick-dots li {
    margin: 0 20px 0 20px;
}

@media(max-width: 1160px ){
    .when-use .container-text ul {
        font-size: 20px;
    }
    
    .when-use .container-text ul p {
        font-size: 20px;
    }
}

@media(max-width: 900px ){
    .when-use .container-text h2 {
        font-size: 35px;
    }

    .when-use .container-text {
        padding-top: 30px;
        padding-left: 30px;
    }
}

@media(max-width: 764px ){
    .when-use .contenedor {
        flex-direction: column;
    }

    .when-use .container-text {
        width: 100%;
        padding-top: 10px;
        padding-left: 10px;
    }
    .when-use .container-img {
        width: 100%;
    }

    .when-use .container-text *{
        margin: 5px;
    }

    .when-use .container-text p {
        font-size: 18px;
    }
    
    .when-use {
        padding: 10px;
        padding-bottom: 50px;
    }

    .when-use .slick-dots button::before {
        font-size: 100px;
    }
    
    .when-use .slick-dots li {
        margin: 0 10px 0 10px;
    }
}