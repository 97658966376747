.extra-section{
  padding: 50px 0 50px 0;
}

.extra-section h1{
  font-weight: bold;
  color: #FF4525;
}



.extra-section .contenedor{
  margin: auto;
  display: flex;
  flex-direction: row;
  max-width: 1250px;
  justify-content: space-around;
  padding: 50px 0 50px 0;
}

.extra-section .contenedor .extra{
  width: 30%;
}

.extra-section .contenedor .extra img{
  width: 100%;
  height: auto;
  border-radius: 20px;
}

@media(max-width: 764px){
  .extra-section{
    padding: 25px 0 25px 0;
  }
  
  
  .extra-section .contenedor{
    padding: 25px 0 25px 0;
  }

  .extra-section .contenedor .extra img{
    border-radius: 10px;
  }

}