#inicio .content {
  background-color: #212529;
  height: 100%;
  padding: 4rem 0px;
}

#inicio .left>.row {
  /* margin-left: 25%;
  margin-right: -50%; */
}

#inicio .right>.row {
  /* margin-left: -40%;
  margin-right: 25%; */
}

#inicio .fade-border {
  margin-top: -12vw;
}

.gradient-in {
  background: linear-gradient(180deg, #364550 0%, #364550 60%, transparent 100%);
  height: 8rem;
  z-index: 1;
  position: absolute;
  width: 100%;
}

.gradient-out {
  background: linear-gradient(180deg, transparent 0%, transparent 20%, #364550 100%);
  height: 8rem;
  z-index: 1;
  position: absolute;
  width: 100%;
  margin-top: -5.5rem
}

/* #inicio .fullscreen .content {
  margin-top: 0px;
} */

@media (min-width: 576px){
  .gradient-out {
    margin-top: -6rem;
  }
}

@media (min-width: 768px){
  #inicio .content {
    
    height: 100%;
    padding: 0px;
  }
  
  #inicio .left>.row {
    margin-left: 25%;
    margin-right: -50%;
  }
  
  #inicio .right>.row {
    margin-left: -40%;
    /* margin-right: 25%; */
  }
  
  #inicio .fade-border {
    margin-top: -15vw;
  }

  .gradient-in {
    background: linear-gradient(180deg, #364550 0%, #364550 60%, transparent 100%);
    height: 10rem;
  }

  .gradient-out {
    background: linear-gradient(180deg, transparent 0%, transparent 20%, #364550 100%);
    height: 8rem;
    z-index: 1;
    position: absolute;
    width: 100%;
    margin-top: -8rem
  }

  #inicio .fullscreen .content {
    margin-top: 0px;
  }
}