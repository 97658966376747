/* */
.main_descargar {
    position: relative;
    align-content: center;
    background-color: #FF4525;
    background-size: cover;
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;
}

/* Estilos para el fondo de celulares */
.fondo_celulares {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.fondo_celulares img {
    width: 90%;
}

/* Estilos para el pseudo-elemento ::before */
.main_descargar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(90, 90, 90, 0.3);
    pointer-events: none;
    z-index: 1;
}

/* Estilos para el contenedor principal */
.main_descargar .contenedor {
    transform: translate(20vw, 25px);
    position: relative;
    width: 40vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
    padding: 5vw;
    border-radius: 1.5vw;
    z-index: 2;
    margin-top: 3vw;
    margin-bottom: 7vw;
}

/* Estilos para los elementos de marca */
.main_descargar .marca {
    background-color: #ffffff;
    width: 70%;
    margin: auto;
    padding: 10px;
    border-radius: 15px;
}

.main_descargar .marca .img,
.main_descargar .marca .texto {
    height: 60px;
    width: auto;
}

.main_descargar .marca h3 {
    padding-top: 25px;
    font-size: 15px;
}

/* Estilos para el encabezado */
.main_descargar .encabezado {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Estilos para la sección de descarga */
.main_descargar .descarga_aqui {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Estilos para los enlaces de descarga */
.main_descargar .enlaces {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.main_descargar .enlaces .btnDescarga {
    flex: 1 1 calc(50% - 20px);
}

.main_descargar .contenedor .hr {
    margin: auto;
    height: 2px;
    width: 80%;
    background-color: black;
    opacity: 0.7;
}

.main_descargar .encabezado p {
    margin: 0;
}

.main_descargar .btnDescarga a {
    text-decoration: none;
    max-width: 300px;
    margin: auto;
    padding: 10px 20px;
    border-radius: 0.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #ffffff;
    transition: background-color 0.3s ease;
}

.main_descargar .btnDescarga a:hover {
    background-color: #adadad;
}

.main_descargar .btnDescarga img {
    height: 50px;
}

.main_descargar .btnDescarga p {
    background-color: #ffffff;
    border-radius: 5px;
    font-size: 18px;
    margin: 0;
    width: 60%;
    color: #000000;
}

/* Estilos responsivos */
@media(max-width: 1500px) {
    .main_descargar .contenedor {
        transform: translate(20vw, 3.5vw);
        width: 45vw;
        font-size: 1vw;
    }

    .main_descargar .marca h3 {
        padding-top: 1vw;
        font-size: 1vw;
    }

    .main_descargar .marca .img {
        height: 4.5vw;
    }

    .main_descargar .marca .texto {
        height: 3vw;
    }

    .main_descargar .btnDescarga img {
        height: 2.5vw;
    }

    .main_descargar .btnDescarga p {
        font-size: 1.5vw;
    }

    .main_descargar .descarga_aqui,
    .main_descargar .enlaces {
        gap: 1vw;
    }
}

@media(max-width: 764px) {

    .main_descargar .marca {
        background-color: #ffffff;
        width: 100%;
        margin: auto;
        padding: 10px;
        border-radius: 15px;
    }

    .fondo_celulares {
        display: none;
    }

    .main_descargar {
        padding: 20px;
    }

    .main_descargar::before {
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 0;
    }

    .main_descargar .encabezado{
        margin-bottom: 20px;
    }

    .main_descargar .contenedor {
        transform: translate(0, 0);
        background-color: #ffffff;
        width: 90%;
        gap: 40px;
        font-size: 15px;
    }

    .main_descargar .marca .img {
        height: 60px;
    }

    .main_descargar .marca .texto {
        height: 40px;
    }

    .main_descargar .marca h3 {
        padding-top: 25px;
        font-size: 15px;
    }



    .main_descargar .enlaces {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
    
    .main_descargar .enlaces .btnDescarga {
        flex: 1 1 calc(50% - 20px);
    }

    .main_descargar .contenedor .hr {
        width: 80%;
    }

    .main_descargar .encabezado img {
        height: 20px;
    }

    .main_descargar .encabezado p {
        margin: 0;
    }

    .main_descargar .btnDescarga a {
        width: 95%;
        background-color: #ebebeb;
        border-radius: 10px;
    }

    .main_descargar .btnDescarga img {
        height: 50px;
    }

    .main_descargar .btnDescarga p {
        font-size: 18px;
        width: 60%;
        color: #000000;
    }
}

@media(max-width: 550px){
    .main_descargar .enlaces {
        flex-direction: column;
        gap: 15px;
    }

    .main_descargar .btnDescarga a {
        width: 90%;
    }
}

@media(max-width: 300px){
    .main_descargar .btnDescarga p {
        display: none;
    }

    .main_descargar .btnDescarga img {
        margin: auto;
    }
}