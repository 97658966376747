.main_cualidad{
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-content: flex-start;
    padding: 25px 0;
    background-color: #ffffff;
    border-radius: 30px;
}

.main_cualidad .img_cualidad{
    width: 80%;
    margin: auto;
    border-radius: 20px;
}


.main_cualidad h4{
    width: 95%;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.main_cualidad p{
    margin: auto;
    width: 80%;
    text-align: center;
}

.main_cualidad .icon_cualidad{
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 0;
    padding: 5px;
    border: none;
}