.main_bitacoraMovil {
    width: 100%;
}

.main_bitacoraMovil .manual{
    padding-top: 0 !important;
}


.main_bitacoraMovil .informacion {
    background-color: #Ffffff !important;
}


.main_bitacoraMovil .pasos {
    margin: auto;
    max-width: 900px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.main_bitacoraMovil .manual .pasos .numero {
    max-width: 100px;
    width: 20%;
    min-width: 60px;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    background-color: rgba(0, 0, 0, 0.123);
    height: 100%;
    border-radius: 10px;
}

.main_bitacoraMovil .manual .pasos .numero p {
    font-size: 25px;
    width: 40px;
    height: 40px;
    align-self: center;
    background-color: #FF4525;
    color: #ffffff;
    border-radius: 15px;
    margin-top: 10px;
}

.main_bitacoraMovil .manual .pasos .numero img {
    width: 50%;
    margin: auto;
    margin-bottom: 10px;
}

.main_bitacoraMovil .pasos .contenido {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.main_bitacoraMovil .pasos .contenido .texto {
    width: 100%;
    text-align: left;
    padding: 10px;
    padding-right: 20px;
    border-radius: 15px;
}

.main_bitacoraMovil .pasos .contenido .texto li {
    padding: 10px 0;
}

.main_bitacoraMovil .pasos .contenido .texto p {
    margin: 0;
}

.main_bitacoraMovil .pasos .contenido .imagenes {
    text-align: left;
    min-width: 100%;
}

.main_bitacoraMovil .pasos .contenido .imagenes img {
    width: 50%;
    background-color: #0000004d;
    border:1px solid #FF4525;
    border-radius: 10px;
}

.main_bitacoraMovil .contenedor{
    position: relative;
}

.main_bitacoraMovil .puntos_navegacion {
    position: fixed;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #00000046;
    padding: 5px;
    border-radius: 10px;
}

.main_bitacoraMovil .puntos_navegacion a{
    height: 15px;
    width: 15px;
    background-color: #FF4525;
    border: none;
    border-radius: 100px;
    cursor: default ;
    scale: 1;
    transition: all 0.2s ease;
    font-size: 0;
}

.main_bitacoraMovil .puntos_navegacion a:hover{
    scale: 2;
    font-size: 10px;
    color: #Ffffff;
}

@media(max-width: 764px) {
    .main_bitacoraMovil .titulo h3 {
        font-size: 10vw !important;
    }

    .main_bitacoraMovil .pasos .contenido .texto li {
        padding: 5px 0;
    }

    .main_bitacoraMovil .manual .pasos .numero p {
        font-size: 20px;
        width: 30px;
        height: 30px;
        align-self: center;
        background-color: #FF4525;
        color: #ffffff;
        border-radius: 8px;
        margin-top: 10px;
    }

    .main_bitacoraMovil .pasos .contenido .imagenes img {
        width: 80%;
        background-color: #0000004d;
    }
}