/* caracteristicas */
.caracteristicas_marcacion {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
}

.caracteristicas_marcacion .caracteristica {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 250px;
    background-color: #c4c4c4;
    color: #000000;
    border-radius: 10px;
    padding: 25px 25px 0px 25px;
    transition: all 0.3s ease;
    border: none;
}

.caracteristicas_marcacion img {
    width: 90px;
    transition: all 0.3s ease;
    border-radius: 10px;
    background-color: #ffffff7d;
    padding: 10px;
}

.caracteristicas_marcacion .caracteristica:hover {
    background-color: #5F5E5E;
    color: #ffffff;
}

.caracteristicas_marcacion .caracteristica:hover img {
    filter: brightness(0) invert(1);
    background-color: #ffffff00;
    padding: 0;
}

/* secciones */
.slide-secciones {
    max-width: 1200px;
    width: 100vw;
}

.slide-secciones .container_seccion {
    display: flex;
    flex-direction: row;
}

.slide-secciones .container_seccion .texto {
    width: 50%;
}

.slide-secciones .container_seccion .texto * {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 75%;
}


.slide-secciones .container_seccion .iconos img {
    width: 70px;
}


/* secciones */
.slide-secciones {
    max-width: 1200px;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

.slide-secciones .secciones_container {
    max-width: 500px;
}

.slide-secciones .img_slider img {
    width:  400px;
}

.slide-secciones .secciones_container .seccion {
    display: flex;
    flex-direction: row;
}

.slide-secciones .secciones_container .seccion .texto {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.slide-secciones .secciones_container .seccion h3 {
    font-size: calc(1.6rem + .6vw);
    color: #EB472A;
}

.slide-secciones .secciones_container .seccion .texto .imagenes_section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.slide-secciones .secciones_container .seccion .texto button {
    border: none;
}

.slide-secciones .secciones_container .seccion .texto img {
    height: 100px;
    width: 100px
}

.slide-secciones .secciones_container .seccion .texto .seleccionada {
    height: 125px;
    width: 125px;
    background-color: #EB472A;
    padding: 10px;
    border-radius: 100px;
}


@media(max-width: 900px) {
    .slide-secciones {
        margin: auto;
        flex-direction: column;
        align-items: center;
    }
}

@media(max-width: 550px) {
    .slide-secciones .secciones_container .seccion .texto .imagenes_section {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: center;
    }

    .slide-secciones .img_slider img {
        width: 90vW;
        height: auto;
    }
}