.admin-section2 {
  position: relative;
  padding: 50px 20px;
}

.admin-section2 .contenedor {
  max-width: 1300px;
  margin: auto;
  width: 100%;
}



.admin-section2 .contenedor .titulo {
  color: #FF4525;
  font-weight: bold;
}

.admin-section2 .contenedor .info {
  position: relative;
  height: 40vw;
}

.admin-section2 .contenedor .info .img_fondo {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.admin-section2 .contenedor .info .detalles {
  position: absolute;
  width: 50%;
  z-index: 2;
  text-align: start;
  padding-top: 5vw;
  padding-left: 60px;
}

.admin-section2 .contenedor .info .detalles .puntos {
  margin-top: 3vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin-section2 .contenedor .info .detalles .puntos .punto {
  width: 90%;
  background-color: #212529;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
}

.admin-section2 .contenedor .info .detalles .puntos p {
  font-size: 1.3vw;
  margin: 0;
  color: #ffffff;
  margin-left: 10px;
}

.admin-section2 .contenedor .info .detalles .puntos img {
  height: 30px;
}

.admin-section2 .contenedor .img_fondo img {
  width: 100%;
}

.admin-section2 .contenedor .extra {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
  background-color: #212529;
  color: #Ffffff;
  padding: 15px;
  border-radius: 10px;
}

.admin-section2 .contenedor .extra {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.admin-section2 .contenedor .extra p {
  margin: 0;
  width: 60%;
  text-align: start;
}

.admin-section2 .contenedor .extra img {
  max-width: 100px;
}

@media(min-width: 1300px ){

  .admin-section2 .contenedor .info {
    height: 550px;
  }

  .admin-section2 .contenedor .info .detalles .puntos p {
    font-size:15px;
    margin: 0;
    color: #ffffff;
    margin-left: 10px;
  }
  
  .admin-section2 .contenedor .info .detalles {
    padding-top: 80px;
  }

  .admin-section2 .contenedor .info .detalles .puntos {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media(max-width: 764px) {
  .admin-section2 .img_fondo {
    display: none;
  }

  .admin-section2 .contenedor .info .detalles {
    position: relative;
    width: 90%;
    margin: auto;
    padding-top: 5vw;
    padding-left: 0;
  }

  .admin-section2 .contenedor .info .detalles .puntos p {
    font-size: 1.8vw;
  }

  .admin-section2 .contenedor .info .detalles .puntos .punto {
    width: 100%;
    background-color: #212529;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
  }

  .admin-section2 .contenedor .info {
    height: auto;
  }

  .admin-section2 .contenedor .extra {
    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
    gap: 15px;
  }

  .admin-section2 .contenedor .extra p {
    width: 90%;
    text-align: center;
    font-size: 12px;
  }
}

@media(max-width: 448px) {

  .admin-section2 .contenedor .info .detalles .puntos p {
    font-size: 2.2vw;
  }

}