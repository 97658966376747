.main_nav {
  background-color: #212529;
}


.main_nav  .pn-link {
  color: white;
  margin: 0rem 1rem;
}

.main_nav .navbar-nav a,
.main_nav .navbar-nav .nav-item{
  align-content: center;
}

.main_nav  .navbar-toggler {
  border-color: white;
}

.main_nav .pn-link:hover {
  color: #FF4525;
}

.main_nav .dropdown .dropdown-menu{
  background-color: #33383b;
}

.main_nav .dropdown .dropdown-menu a{
  color: #ffffff;
  text-align: start;
}

.main_nav .dropdown .dropdown-menu a:hover{
  background-color: #5e5d5d;
}


.main_nav .login p {
  margin: 0;
}

.main_nav .login {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.main_nav .pn-link .icon-login {
  width: 30px;
  height: 30px;
  transition: all 0.3s ease;
  filter: brightness(0) invert(1);
  align-self: center;
}

.main_nav .pn-link:hover .icon-login {
  filter: brightness(1) invert(0);
}

.main_nav .dropdown-item {
  color: #000000;
}

.main_nav .dropdown-item:hover {
  background-color: #212529;
  color: #ffffff;
}

.main_nav .nav_right{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 1200px) {

  .main_nav .login p {
    display: none;
  }
}

@media (max-width: 992px) {
  .main_nav .login p {
    display: block;
  }

  .main_nav .nav_right{
    flex-direction: column;
  }

  .main_nav .dropdown .dropdown-menu a{
    color: #ffffff;
    text-align: center;
  }
}



@media (min-width: 992px) {
  .main_nav .pn-link {
    font-size: 1em;
  }

  
}


@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
  }
}

