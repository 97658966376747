.main-footer {
	background-color: #212529;
	display: flex;
	flex-direction: column;
	width: 100%;
	color: #ffffff;
	text-align: left;
	font-size: 15px;
}

.main-footer .contenedor {
	padding-top: 20px;
	display: flex;
	flex-direction: row;
	max-width: 1100px;
	margin: auto;
	justify-content: space-between;
}

.main-footer .contenedor hr {
	width: 60%;
	margin-top: 0;
}

.main-footer .contenedor h3 {
	font-size: 17px;
	font-weight: bold;
}

.main-footer .contenido {
	width: 75%;
	display: flex;
	flex-direction: row;
}

.main-footer .nosotros {
	width: 20%;
	min-width: 200px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	text-align: center;
}

.main-footer .nosotros .marca {
	width: 80%;
	margin: 0 auto;
}

.main-footer .nosotros .marca img {
	width: 100%;
}

.main-footer .contactos,
.main-footer .enlaces {
	width: 50%;
	padding: 15px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.main-footer .enlaces .redes,
.main-footer .enlaces .opciones {
	width: 70%;
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.main-footer .enlaces img {
	width: 35px;
	height: auto;
	opacity: 0.6;
}

.main-footer .enlaces img:hover {
	opacity: 1;
}

.main-footer .copyright {
	margin: auto;
	font-size: 12px;
}

.main-footer .separador-bajo {
	margin: 0;
}

.main-footer .copyright p {
	margin: 10px;
	text-align: center;
}

@media(max-width:764px) {
	.main-footer .contenedor {
		flex-direction: column;
		align-items: center;
	}

	.main-footer .nosotros {
		min-width: 300px;
		flex-direction: row;
	}

	.main-footer .contenedor h3 {
		text-align: center;
	}

	.main-footer .contenedor hr {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}

	.main-footer .enlaces .redes,
	.main-footer .enlaces .opciones {
		margin: auto;
		justify-content: space-around;

	}

	.main-footer .enlaces .opciones {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
	}
}

@media(max-width:450px) {
	.main-footer .contenedor .contenido {
		flex-direction: column;
	}

	.main-footer .contactos,
	.main-footer .enlaces {
		width: 100%;
		text-align: center;
	}

	.main-footer .nosotros {
		flex-direction: column;
	}
}