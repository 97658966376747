.main_tracking {
    width: 100%;
}

/* titulo */
.main_tracking .contenedor .titulo_marcacion {
    margin-bottom: 40px;
}

.main_tracking .contenedor .titulo_marcacion h3 {
    font-size: calc(1.5rem + .6vw);
}

.main_tracking .contenedor .titulo_marcacion h4 {
    color: #EB472A;
}


/* Puntos */
.main_tracking .contenedor .puntos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.main_tracking .contenedor .puntos .punto {
    display: flex;
    flex-direction: column;
    width: 250px;
    text-align: center;
}

.main_tracking .contenedor .puntos .punto img {
    width: 50px;
    align-self: center;
}

/* caracteristicas */
.main_tracking .contenedor .caracteristicas_horario {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
}

.main_tracking .contenedor .caracteristicas_horario .caracteristica {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 250px;
    background-color: #c4c4c4;
    color: #000000;
    border-radius: 10px;
    padding: 25px 25px 0px 25px;
    transition: all 0.3s ease;
    border: none;
}

.main_tracking .contenedor .caracteristicas_horario img {
    width: 90px;
    transition: all 0.3s ease;
    border-radius: 10px;
    background-color: #ffffff7d;
    padding: 10px;
}

.main_tracking .contenedor .caracteristicas_horario .caracteristica:hover {
    background-color: #5F5E5E;
    color: #ffffff;
}

.main_tracking .contenedor .caracteristicas_horario .caracteristica:hover img {
    filter: brightness(0) invert(1);
    background-color: #ffffff00;
    padding: 0;
}

@media(max-width: 1200px) {

    .main_tracking .contenedor .slide-carrusel .slick-slide>div {
        margin: 0 0px;
    }

    .main_tracking .contenedor .slide-carrusel .slick-list {
        margin: 0 -5px;
    }

    .main_tracking .contenedor .slide-carrusel .slick-slide {
        opacity: 1;
    }
}


/* secciones */
.main_tracking .contenedor .slide-secciones {
    max-width: 1200px;
    width: 100vw;
}

.main_tracking .contenedor .slide-secciones .container_seccion {
    display: flex;
    flex-direction: row;
}

.main_tracking .contenedor .slide-secciones .container_seccion .texto {
    width: 50%;
}

.main_tracking .contenedor .slide-secciones .container_seccion .texto * {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 75%;
}


.main_tracking .contenedor .slide-secciones .container_seccion .iconos img {
    width: 70px;
}


/* secciones */
.main_tracking .contenedor .slide-secciones {
    max-width: 1200px;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

.main_tracking .contenedor .slide-secciones .secciones_container {
    max-width: 500px;
}

.main_tracking .contenedor .slide-secciones .secciones_container .seccion {
    display: flex;
    flex-direction: row;
}

.main_tracking .contenedor .slide-secciones .secciones_container .seccion .texto {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.main_tracking .contenedor .slide-secciones .secciones_container .seccion h3 {
    font-size: calc(1.6rem + .6vw);
    color: #EB472A;
}

.main_tracking .contenedor .slide-secciones .secciones_container .seccion .texto .imagenes_section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.main_tracking .contenedor .slide-secciones .secciones_container .seccion .texto button {
    border: none;
}

.main_tracking .contenedor .slide-secciones .secciones_container .seccion .texto img {
    height: 100px;
    width: 100px
}

.main_tracking .contenedor .slide-secciones .secciones_container .seccion .texto .seleccionada {
    height: 125px;
    width: 125px;
    background-color: #EB472A;
    padding: 10px;
    border-radius: 100px;
}