.main-contact {
    width: 100%;
    display: flex;
    padding: 0 0 30px 0;
    flex-direction: column;
    background-color: #FF4525;
    background-size: cover;
    text-align: center;
}

.content-contact{
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.main-contact h1 {
    font-size: 40px;
    color: #ffffff;
    padding: 30px;
}

.hr-contact-form {
    border: none;
    padding: 0;
    margin: 0;
    align-self: center;
    height: 600px;
    width: 1.5px;
    background-color: #000000;
    opacity: 0.5;
    filter: var(--color-icono);
}


@media(max-width: 950px) {

    .content-contact{
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .hr-contact-form {
        height: 1.5px;
        width: 80%;
    }
}

@media (max-width: 750px) {

    .main-contact h1 {
        font-size: 30px;
        padding: 25px;
    }

    .top-contact {
        flex-direction: column-reverse;
    }

}