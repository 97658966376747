.main_bitacora {
    width: 100%;
}

.main_bitacora .manual .pasos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    padding: 15px;
}


.main_bitacora .manual .paso {
    text-align: left;
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-radius: 20px;
    background-color: #00000023;
    gap: 20px;
}

.main_bitacora .manual .pasos .derecha {
    align-self: flex-end;
}

.main_bitacora .manual .pasos .izquierda {
    align-self: flex-start;
}

.main_bitacora .manual .paso .texto {
    align-self: center;
    text-align: left;
    max-width: 400px;
}

.main_bitacora .manual .paso .img_paso {
    max-width: 300px;

}

.main_bitacora .manual .paso .img_paso img {
    width: 100%;
    border-radius: 12px;
}

.main_bitacora .manual .paso .info_extra {
    font-size: 12px;
    font-weight: bold;
    background-color: #ffffff;
    max-width: 200px;
    padding: 10px;
    border-radius: 10px;
}



.main_bitacora .main_cualidad .img_cualidad {
    width: 60%;
    margin: auto;
    border-radius: 20px;
    border: none;
}

.main_bitacora .pasos_extra {
    display: flex;
    flex-direction: column;
}

.main_bitacora .manual .pasos .pasos_extra ul {
    width: 90%;
    margin: auto;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-left: 0;
}

.main_bitacora .manual .pasos .pasos_extra ul li {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
}

.main_bitacora .manual .pasos .pasos_extra ul li h6 {
    background-color: #FF4525;
    margin: 0;
    margin-right: 7px;
    color: #ffffff;
    width: 25px;
    height: 25px;
    text-align: center;
    align-content: center;
    border-radius: 100px;
    scale: 1.2;
}

.main_bitacora .btnCollapse {
    display: flex;
    flex-direction: row;
    background-color: #212529;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    padding: 10px 0;
    align-content: center;
}

.main_bitacora .btnCollapse p {
    font-size: 1.2em;
    font-weight: 100;
    width: 100%;
    margin: 0;
}

.main_bitacora .btnCollapse img {
    width: 30px;
    height: auto;
    margin-right: 10px;
    align-self: center;
    transition: all 0.3s ease;
}

.main_bitacora .btnCollapse .clicked {
    transform: rotate(180deg);
}


.main_bitacora .pasos_extra .paso_extra {
    text-align: left;
}

.main_bitacora .pasos_extra .paso_extra .content{
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main_bitacora .pasos_extra .paso_extra .imgCollapse{
    width: 50%;
}


.main_bitacora .pasos_extra .paso_extra .imgCollapse img{
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: contain;
}

.main_bitacora .pasos_extra .paso_extra .textoCollapse {
    width: 40%;
}

.main_bitacora .pasos_extra .paso_extra .textoCollapse h3 {
    font-size: 35px;
}

.main_bitacora .pasos_extra .paso_extra .textoCollapse .btnInfo {
    background-color: transparent;
    border: none;
    border-radius: 5px;
    padding: 7px;
    opacity: 1;
    width: 44px;
    height: 44px;
}

.main_bitacora .pasos_extra .paso_extra .textoCollapse .btnInfo img {
    width: 30px;
    height: 30px;
}

.main_bitacora .pasos_extra .paso_extra .textoCollapse span {
    display: flex !important;
    flex-direction: row;
    align-content: center;
    gap: 10px;
}

.main_bitacora .msgInfoExtra {
    align-content: center;
    max-width: 250px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    opacity: 0;
    transition: all 0.3s ease;
}

.main_bitacora span:hover .msgInfoExtra {
    opacity: 1;
}

.main_bitacora .textoCollapse span h6 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}

@media(max-width: 764px) {
    .main_bitacora .manual .paso {
        flex-direction: column;
    }

    .main_bitacora .manual .paso .img_paso {
        max-width: 400px;
        margin: auto
    }

    .main_bitacora .pasos_extra .paso_extra .textoCollapse {
        width: 100%;
    }
    
    .main_bitacora .pasos_extra .paso_extra .content{
        flex-direction: column;
    }

    .main_bitacora .pasos_extra .paso_extra .imgCollapse{
        width: 80%;
        margin: auto;
        padding-top: 20px;
    }
    
}