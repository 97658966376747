.main_productos {
    background-color: #eeeeee;
    width: 100%;
    padding: 0;
    text-align: center;
}

.main_productos .contenedor {
    display: flex;
    flex-direction: column;
}

.main_productos .opciones_productos {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 10px;
    margin-top: 20px;
    gap: 20PX;
    max-width: 1500px;
}

.main_productos .opciones_productos li {
    list-style: none;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    padding: 10px 10px;
    padding-bottom: 0;
    border-bottom: #FF4525;
    background-color: #ffffff;
}


.main_productos .opciones_productos li .active P {
    background-color: #212529;
}

.main_productos a {
    text-decoration: none;
    color: #000000;
    font-size: 15px;

}

.main_productos .texto_productos {
    height: 30px;
    margin-top: 20px;
    margin-bottom: 0;
    transition: all 0.2s ease;
    background-color: #FF4525;
    border-radius: 10px 10px 0px 0px;
    align-content: center;
    color: #ffffff;
    font-size: 17px;
}

.main_productos a img {
    max-width: 150px;
    width:50%;
}

.main_productos .titulo {
    background-color: #212529;
    display: flex;
    flex-direction: column-reverse;
    padding: 15px 0;
}

.main_productos .titulo img {
    width: 80px;
    margin: 10px auto;
    margin-top: 0;
}

.main_productos .titulo h3 {
    color: #ffffff;
    text-align: start;
    font-size: 60px;
    margin: auto;
}

.main_productos .manual {
    padding: 50px 0;
    padding-bottom: 70px;
    margin: auto;
    max-width: 1200px;
}

.main_productos .informacion .descripcion {
    margin: auto;
    max-width: 900px;
    width: 80%;
}

.main_productos .informacion .cualidades {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    padding: 20px;
}

.main_productos .informacion {
    background-color: #e2e2e2;
    padding: 30px 0;
}

@media(max-width: 1115px) {
    .main_productos .opciones_productos li {
        list-style: none;
        border-radius: 20px;
    }
}

@media(max-width: 764px) {
    .main_productos .opciones_productos li {
        padding: 10px 25px;
        padding-bottom: 0;
    }

    .main_productos p {
        font-size: 13px;
    }

    .main_productos .texto_productos {
        font-size: 2.5vw;
    }


}